import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import OnMarketContainer from "../components/FormBuilder/OnMarketContainer/OnMarketContainer";
import "../styles/form.scss";

const OnMarket = () => (
  <AuthenticationLayout>
    <OnMarketContainer />
  </AuthenticationLayout>
);

export default OnMarket;
