import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Contacts from "../../../Interfaces/contacts";
import { useAppDispatch } from "../../../store";
import { getContactsFromId } from "../../../store/Communications/actions";
import { selectAllContacts } from "../../../store/Communications/selectors";
import { submitSurvey, SurveyData } from "../../../store/Surveys/actions";
import { resetSurvey } from "../../../store/Surveys/reducer";
import {
  selectSurveysError,
  selectSurveysSuccess,
} from "../../../store/Surveys/selectors";
import "../../../styles/fields.scss";
import { checkRequiredFields, sendToast } from "../../../utils/helpers";
import DefaultButton from "../../Buttons/DefaultButton";
import useRecentlyMatchedDefaultFormBuilder from "../FormHook/defaultFormHook";
import { getFields } from "./config";

const OnMarketContainer = () => {
  const dispatch = useAppDispatch();
  const { winning_agent } = (useSelector(state =>
    selectAllContacts(state)
  ) as Contacts) || { winning_agent: null };
  const [config, setConfig] = useState(null) as any[];
  const [loading, setLoading] = useState(false);
  const [fields, data] = useRecentlyMatchedDefaultFormBuilder({ config });
  const success = useSelector(state => selectSurveysSuccess(state));
  const error = useSelector(state => selectSurveysError(state));

  useEffect(() => {
    if (success) {
      setLoading(false);
      navigate("/success/", {
        state: {
          type: "OnMarket",
        },
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      sendToast(
        error.error ||
          "There was an error submitting the survey, please try again.",
        { type: "error" }
      );
      dispatch(resetSurvey());
    }
  }, [error]);

  useEffect(() => {
    if (winning_agent) {
      const newFields = getFields(winning_agent);
      setConfig(newFields);
    }
  }, [winning_agent]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const dealId = urlParams.get("deal_id");
      if (!dealId) {
        toast("No Deal Id was attached");
        navigate("/404/", {
          state: {
            error:
              "There was an error with your form. Please contact a Clever representative at 833-7-CLEVER",
          },
        });
      } else {
        dispatch(getContactsFromId(dealId));
      }
    }
  }, []);

  const handleFormSubmit = () => {
    setLoading(true);
    const { invalid, requiredLabelName } = checkRequiredFields(config, data);
    if (!invalid) {
      const urlParams = new URLSearchParams(window.location.search);
      const dealId = urlParams.get("deal_id");
      const dataFields = {
        deal_id: dealId,
        data: {
          ...data,
          survey_name: "On Market Survey",
        },
      } as SurveyData;

      dispatch(submitSurvey(dataFields));
    } else {
      setLoading(false);
      sendToast(`Please fill out the following field: ${requiredLabelName}`, {
        type: "error",
      });
    }
  };

  return (
    <div className="defaultFormContainer">
      <h2>On Market Survey</h2>
      <div className="defaultFormBody">
        <div>
          {fields}
          {fields && fields.length === 0 && (
            <p>
              There was an error with the form. Please contact Clever at
              833-7-CLEVER.
            </p>
          )}
          {fields.length > 0 && (
            <DefaultButton
              text={loading ? "Loading" : "Submit"}
              className="defaultButton"
              disabled={loading}
              callback={() => handleFormSubmit()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnMarketContainer;
