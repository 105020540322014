import Agent from "../../../Interfaces/agent";
import { FieldTypes } from "../../../utils/fieldTypes";

const RecentlyMatchedAgentDefaultFields = (agent: Agent) => {
  const fields = [
    {
      type: FieldTypes.header,
      keyName: `agentHeader${agent.firstname}${agent.lastname}`,
      label: `${agent.firstname} ${agent.lastname ? agent.lastname : ""}`,
    },
    {
      type: FieldTypes.paragraph,
      label: `Please rate each of the following on a scale of 1-5`,
    },
    {
      label: `Quality of the photos taken.`,
      keyName: `quality_of_the_photos_taken`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Quality of the listing description.`,
      keyName: `quality_of_the_listing_description`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Agent's communication / attentiveness`,
      keyName: `agent_s_communication___attentiveness`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Efforts in terms of advertising, scheduling showing, open houses, etc.`,
      keyName: `effort_in_terms_of_advertising__scheduling_showing__open_houses__etc`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `How satisfied are you with the performance of ${agent.firstname} ${agent.lastname} so far?`,
      keyName: `how_satisfied_are_you_with_the_performance_of_winning_agent_so_far_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: "What is negatively impacting your rating?",
      keyName: "what_is_negatively_impacting_your_rating___on_market_",
      type: FieldTypes.textarea,
      triggers: {
        how_satisfied_are_you_with_the_performance_of_winning_agent_so_far_: [
          "1",
          "2",
          "3",
        ],
      },
    },
    {
      label:
        "Would you like Clever to follow up with you or the agent regarding your provided feedback?",
      keyName:
        "would_you_like_clever_to_follow_up_with_you_or_the_agent_regarding_your_provided_feedback_",
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
      triggers: {
        how_satisfied_are_you_with_the_performance_of_winning_agent_so_far_: [
          "1",
          "2",
          "3",
        ],
      },
    },
  ];

  return fields;
};

const RecentlyMatchedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `General Questions`,
  },
  {
    label:
      "Based on your experience so far, how likely are you to refer a friend to Clever?",
    keyName: "how_likely_are_you_to_refer_a_friend_to_clever___on_market_",
    type: FieldTypes.radioNumber,
    required: true,
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
];

const getFields = (agent: Agent) => {
  let fields = [] as any[];
  const agentFields = RecentlyMatchedAgentDefaultFields(agent);
  const generalFields = RecentlyMatchedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
